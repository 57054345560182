import React from "react";
import { styled } from '@mui/system';
import {
    Typography,
} from "@mui/material";


const StyledAssistantContainer = styled('div')({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: 'center'
})

const AssistantImage = styled('img')({
    width: "35vh", 
    borderRadius: "20px",
  });

export default function Assistant({img, name, title, description}){
  return (
    <StyledAssistantContainer>
      <AssistantImage alt="Assistant" src={img} />
      <Typography sx={{mt:2, fontSize: {xs: 18, md: 20}}} variant="h6"><strong>{title} :</strong> {name}</Typography>
      <Typography sx={{mb:2, fontSize: {xs: 12, md: 12}}} variant="p">{description}</Typography>
    </StyledAssistantContainer>
  );
};

