import { useState } from "react";

import { Grid, Box } from "@mui/material";

import { PlanCard } from "../../custom/card";

import PersonIcon from '@mui/icons-material/Person';
import BarChartIcon from '@mui/icons-material/BarChart';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';

import * as theme from '../../../theme'
import { CREATE_CHECKOUT_SESSION, PAYMENT } from "../../../apiEndpoints";
import axios from "axios";
import { Alert, QAlert } from "../../custom/alert";
import { SubscribeButton } from "../../custom/button";


const avatar_style = { width: 80, height: 80 }
const plan_avatar = [
<PersonIcon  sx={avatar_style} />,
<AutoGraphIcon  sx={avatar_style} />,
<AutoFixHighIcon  sx={avatar_style} />,
<BarChartIcon  sx={avatar_style} />,
<AutoFixNormalIcon  sx={avatar_style} />,
<PersonIcon  sx={avatar_style} />,
]

export default function Subscriptions({user, token, removeToken, removeUser, setUser, setLoading, products}) {
  const jwtToken = token
  const [alert, setAlert] = useState({
    open: false,
    title: "",
    message: ""
   });
   const [qAlert, setQAlert] = useState({
     open: false,
     title: "",
     message: ""
    });
  
   const handleAlertOpen = (title, message) => {
    setAlert({
      open: true,
      title: title,
      message: message
    });
   };
   const handleAlertClose = () => {
    setAlert({
      open: false,
      title: "",
      message: ""
    });
   };

   const handleQAlertOpen = (title, message) => {
    setQAlert({
      open: true,
      title: title,
      message: message
    });
   };

   const handleQAlertClose = () => {
    setQAlert({
      open: false,
      title: "",
      message: ""
    });
   };

   const handleAlertAgree = (title, message) => {
    setQAlert({
      open: false,
      title: "",
      message: ""
    });

    setLoading(true)

    axios.put(
      PAYMENT, {}, 
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + jwtToken
        }
      }
    )
    .then(response => response.data)
    .then(response => {
      setLoading(false)
      setUser(response.data)
      handleAlertOpen("Success", "Your subscription has been canceled")
    })
    .catch(error => {
      setLoading(false)
      const errorResponse = error.response
      handleAlertOpen("Error", errorResponse!==undefined?errorResponse.data.message:error.message)
    });
   };
   const handleAlertDisagree = () => {
    setQAlert({
      open: false,
      title: "",
      message: ""
    });
   };
  
  // const initiatePayment = (token, product) => {
  //   setLoading(true)
  //   axios.post(
  //     PAYMENT, 
  //     {
  //       token, product
  //     }, 
  //     {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': 'Bearer ' + jwtToken
  //       }
  //     }
  //   )
  //   .then(response => response.data)
  //   .then(response => {
  //     setLoading(false)
  //     const updatedUser = {
  //       ...user,
  //       token_balance: parseInt(user.token_balance, 10) + product.tokens,
  //       subscription_plan: product.name

  //     }
  //     setUser(updatedUser)
  //     handleAlertOpen("Success", response.message)
  //   })
  //   .catch(error => {
  //     setLoading(false)
  //     const errorResponse = error.response
  //     handleAlertOpen("Error", errorResponse!==undefined?errorResponse.data.message:error.message)
  //   });
  // }
  
  const handleProductCheckout = (product_id) => {
    setLoading(true)
    axios.post(
      CREATE_CHECKOUT_SESSION, 
      {
        product_id
      }, 
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + jwtToken
        }
      }
    )
    .then(response => response.data)
    .then(response => {
      setLoading(false)
      const checkout_session_url = response.data
      window.location.href = checkout_session_url;
      
    })
    .catch(error => {
      setLoading(false)
      const errorResponse = error.response
      handleAlertOpen("Error", errorResponse!==undefined?errorResponse.data.message:error.message)
    });
  }

  const handleCancelSubscription = () => {
    handleQAlertOpen("Really", "Are you sure you want to cancel the subscription?")
  }

  return (
    <>
      <Alert alert={alert} handleAlertClose={handleAlertClose} />
      <QAlert alert={qAlert} handleAlertClose={handleQAlertClose} handleAlertAgree={handleAlertAgree} handleAlertDisagree={handleAlertDisagree}  />

      <Grid container spacing={2} display={'flex'} justifyContent={'center'} sx={{px: {xs:1, md:10 }, backgroundColor: theme.BODY_BG}}>
        { 
          products.map(
            (product, index) => {
              return(
              <Grid item xs={12} md={6} xl={3}>
                <PlanCard
                  label={`${product.price} USD / ${product.plan}`}
                  title={product.name}
                  avatar={plan_avatar[index]}
                  description={product.description}
                  tokens={product.tokens}
                  button={
                    !(!user.subscription_plan || user.subscription_plan==="null")?
                    <SubscribeButton onClick={handleCancelSubscription} disabled={user.subscription_plan===product.name?false:true} fullWidth buttonText={user.subscription_plan===product.name?"Cancel Subscription":"Upgrade"} />:
                    // <StripeCheckout 
                    //   stripeKey={process.env.REACT_APP_STRIPE_PUBLISHED_KEY} 
                    //   token={(token) => initiatePayment(token, product)} 
                    //   name={product.name} 
                    //   description={product.description}
                    //   amount={product.price * 100}
                    //   currency="USD"   
                    //   ComponentClass="div"
                    // >
                      <SubscribeButton upgrade onClick={()=>handleProductCheckout(product.id)} fullWidth buttonText={"Upgrade"} />
                    // </StripeCheckout>
                  }           
                />
              </Grid>
              )
            }
           
          )
        }
        
      </Grid>
      <Box>
        
      </Box>
    </>
  );
};