import { Box, Typography, Modal, Divider } from '@mui/material'
import * as theme from '../../theme'
import { useState } from 'react'

import { ActionButton } from '../custom/button'

export default function Footer({type, disclaimer}) {
  const [dislaimerOpen, setDisclaimerOpen] = useState(false)

  return (
    
      type === "assistant"?
      
      
      <Box
      sx={{
        backgroundColor: theme.FOOTER_BG,
        color: theme.FOOTER_FONT,
        boxShadow: 20,
        p: 2,
      }}
      >
        <Typography>
          Copyright - NTI Blockchain, Inc. 2023
          {
            disclaimer && 
            <label style={{marginLeft:5}}>
             - <label style={{ textDecoration: 'underline', cursor: 'pointer', marginLeft:5 }} onClick={() => setDisclaimerOpen(true)}>Disclaimer</label> 
            </label> 
          }
        </Typography>
  
        
       
        
        {/* Disclaimer Modal */}
        <Modal
          open={dislaimerOpen}
          onClose={() => setDisclaimerOpen(false)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={{
            bgcolor: 'white',
            padding: 4,
            margin: 4,
            maxHeight: '80vh',
            overflowY: 'auto',
          }}>
            <Typography variant="h5" id="modal-title" gutterBottom>
              Disclaimer
            </Typography>
  
            <Divider sx={{ backgroundColor: "#000000", mb:2, borderWidth: 2}} />
            <Typography variant="body1" id="modal-description">
                      When using the AIAssistantExpert.com web app that provides AI Assistants for various professions using ChatGPT 4 for verbal conversations, please consider the following points for a comprehensive understanding of the service:
                      <br/>
                      <br/>
                      <strong> AI Capabilities </strong>: The AI assistant is powered by OpenAI's ChatGPT 4, offering conversational capabilities across various domains. However, its responses are generated based on patterns in data and not personal experience or human insight.
                      <br/>
                      <br/>
                      <strong>Data Privacy</strong>: Interactions with the AI might be stored or analyzed for improving the service. Ensure you understand the app's privacy policy and how your data is used and protected.
                      <br/>
                      <br/>
                      <strong>Accuracy and Reliability</strong>: While AI strives for accuracy, it may not always provide reliable or fact-checked information. Users should verify critical information independently.
                      <br/>
                      <br/>
                      <strong>Limitations in Understanding</strong>: AI may not fully understand context, nuance, or cultural specifics. It's essential to use clear and concise language to improve interaction quality.
                      <br/>
                      <br/>
                      <strong>Continuous Learning</strong>: The AI model may update continually to provide better responses. As such, the nature and quality of interactions might change over time.
                      <br/>
                      <br/>
                      <strong>Professional Advice</strong>: AI should not replace professional advice in critical areas like medical, legal, or financial decision-making. Always consult a qualified human professional for significant matters.
                      <br/>
                      <br/>
                      <strong>User Responsibility</strong>: Users are responsible for the queries they input and should avoid sharing sensitive personal information.
                      <br/>
                      <br/>
                      By using the service, you acknowledge the capabilities and limitations of AI technology and agree to use it appropriately and responsibly.                      
                      </Typography>
            
            <ActionButton sx={{mt:4}} onClick={() => setDisclaimerOpen(false)} buttonText={"Close"} />
          </Box>
        </Modal>
      </Box>:
      <Box sx={{ backgroundColor: theme.FOOTER_BG, color: theme.FOOTER_FONT , boxShadow: 20, p: 2 }}>
        <Typography>
          Copyright - NTI Blockchain, Inc. 2023 
          {
            disclaimer && 
            <label style={{marginLeft:5}}>
             - <label style={{ textDecoration: 'underline', cursor: 'pointer', marginLeft:5 }} onClick={() => setDisclaimerOpen(true)}>Disclaimer</label> 
            </label> 
          }
        </Typography>
  
        
       
        
        {/* Disclaimer Modal */}
        <Modal
          open={dislaimerOpen}
          onClose={() => setDisclaimerOpen(false)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={{
            bgcolor: 'white',
            padding: 4,
            margin: 4,
            maxHeight: '80vh',
            overflowY: 'auto',
          }}>
            <Typography variant="h5" id="modal-title" gutterBottom>
              Disclaimer
            </Typography>
  
            <Divider sx={{ backgroundColor: "#000000", mb:2, borderWidth: 2}} />
            <Typography variant="body1" id="modal-description">
                      When using the AIAssistantExpert.com web app that provides AI Assistants for various professions using ChatGPT 4 for verbal conversations, please consider the following points for a comprehensive understanding of the service:
                      <br/>
                      <br/>
                      <strong> AI Capabilities </strong>: The AI assistant is powered by OpenAI's ChatGPT 4, offering conversational capabilities across various domains. However, its responses are generated based on patterns in data and not personal experience or human insight.
                      <br/>
                      <br/>
                      <strong>Data Privacy</strong>: Interactions with the AI might be stored or analyzed for improving the service. Ensure you understand the app's privacy policy and how your data is used and protected.
                      <br/>
                      <br/>
                      <strong>Accuracy and Reliability</strong>: While AI strives for accuracy, it may not always provide reliable or fact-checked information. Users should verify critical information independently.
                      <br/>
                      <br/>
                      <strong>Limitations in Understanding</strong>: AI may not fully understand context, nuance, or cultural specifics. It's essential to use clear and concise language to improve interaction quality.
                      <br/>
                      <br/>
                      <strong>Continuous Learning</strong>: The AI model may update continually to provide better responses. As such, the nature and quality of interactions might change over time.
                      <br/>
                      <br/>
                      <strong>Professional Advice</strong>: AI should not replace professional advice in critical areas like medical, legal, or financial decision-making. Always consult a qualified human professional for significant matters.
                      <br/>
                      <br/>
                      <strong>User Responsibility</strong>: Users are responsible for the queries they input and should avoid sharing sensitive personal information.
                      <br/>
                      <br/>
                      By using the service, you acknowledge the capabilities and limitations of AI technology and agree to use it appropriately and responsibly.                      
                      </Typography>
            
            <ActionButton sx={{mt:4}} onClick={() => setDisclaimerOpen(false)} buttonText={"Close"} />
          </Box>
        </Modal>
      </Box>

    
  )
}
