import React, { useState, useEffect } from 'react'

import axios from 'axios';

import VoiceAssistant from './VoiceAssistant'
import ChooseVoiceAssistant from './ChooseVoiceAssistant'
import Header from '../header/Header';
import Footer from '../footer/Footer';

import * as theme from '../../theme'
import { ASSISTANT } from '../../apiEndpoints'
import Alert from './Custom/Alert';
import { APILoader } from '../custom/loading';
import { useMediaQuery } from '@mui/material';

export default function Main({user, setUser, removeUser, token, removeToken, setLoading}) {
    const [state, setState] = useState({
        choose: true,
        voiceAssistant: false,
    });
    const [apiLoading, setAPILoading] = useState({
        choose: true,
        voiceAssistant: false,
    });
    const [assistantData, setAssistantData] = useState([])
    const [selectedAssistant, setSelectedAssistant] = useState({})
    const [alert, setAlert] = useState({
        open: false,
        message: "",
        title: "",
    });
    const isMobile = useMediaQuery('(max-width:600px)');


    
       const handleAlertClose = () => {
        setAlert({
          open: false,
          title: "",
          message: ""
        });
       };

    const loadAssistant = () => {
        setAPILoading(true)
        axios.get(ASSISTANT, { withCredentials: false, headers: {  Authorization: 'Bearer ' + token } }, )
        .then(response => response.data)
        .then(response => {
          setAPILoading(false)
          const assistants = response.data
          setAssistantData(assistants)
        })
        .catch(error => {
          setAPILoading(false)
          const errorResponse = error.response
          if (errorResponse) if (errorResponse.data.token_expired) {
            removeUser()
            removeToken()
          }
          }
        )
      }
    
      useEffect(() => {
        loadAssistant()
      }, [])
    
    return (
        <>
          <Alert alert={alert} handleAlertClose={handleAlertClose} />
          <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', backgroundColor: theme.BODY_BG }}>
              <div style={{ height: '10vh', }}>
                <Header type="assistant" user={user} removeToken={removeToken} removeUser={removeUser} setLoading={setLoading} />
              </div>
              <APILoader loading={apiLoading} />
              {
                  state.choose?
                  <>
                    <div style={isMobile? {display: 'flex', alignItems: 'center', py:2}: {display: 'flex',py: 5 }}>
                          <ChooseVoiceAssistant user={user} token={token}  removeToken={removeToken} setState={setState} setSelectedAssistant={setSelectedAssistant} assistantData={assistantData} />
                    </ div>
                    <div style={!isMobile? { position: 'fixed', bottom: 0, width: '100%',}: {}}>
                      <Footer type={"assistant"} disclaimer />
                    </div>
                  </>
                  :state.voiceAssistant?
                  <>
                    <div style={isMobile? {display: 'flex', alignItems: 'center', py:2}: {height: '100vh', display: 'flex',  my:1, py: 5 }}>
                      <VoiceAssistant user={user}  setUser={setUser} token={token} removeToken={removeToken} setState={setState}  selectedAssistant={selectedAssistant} chooseAssistant={() => setState({choose: true})}/>:
                    </ div>
                    <div style={!isMobile? { position: 'static', bottom: 0, width: '100%',}: {}}>
                      <Footer type={"assistant"} disclaimer />
                    </div>
                  </>:
                    ""
            }
          </div>  
        </>
          

    )
}
