import React, {useState} from "react"

import {
    Container,
    Box,
    Typography,
    Grid,
    useMediaQuery,
} from "@mui/material"
import { SelectBox } from "../custom/select"
import ActionButton from "../custom/button/ActionButton"

import * as theme from '../../theme'
import AssistantBox from "./AssistantBox"

export default function ChooseVoiceAssistant({user, token, removeToken, setState, setSelectedAssistant, assistantData}) {
   
    const [formData, setFormData] = useState({})

    const isMobile = useMediaQuery('(max-width:600px)');

    const getAssistantDataFromId = (id) => {
        return assistantData?.filter((assistant) => assistant.id === id)[0]
    }

    const handleFormChange = (event) => {
        setFormData((prevValues) => ({
            ...prevValues,
            [event.target.name]: event.target.value,
        }))
      }
    
      const handleAssistantSelect = (event) => {
        event.preventDefault();
        setSelectedAssistant(getAssistantDataFromId(formData.assistant))
        setState({voiceAssistant: true})
        
      }
      

const assistantList = assistantData?.map((assistant) => { return {label: assistant.name + " - " + assistant.title, value: assistant.id} })
return (
    
    <Container
    >
      
      <Box component="form"  onSubmit={handleAssistantSelect} >
        <Box sx={{  width: "100%", py:4,  textAlign: 'center' }}>

          <Box>
              <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Typography style={{ fontSize: 30, fontFamily: 'Courier New, monospace', textShadow: '2px 2px 4px black', fontWeight: 'bold',}} variant='h4'>
                  Welcome { user.business_name ?? user.first_name + " " + user.last_name}
                </Typography>     
              </Box>
              <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{py:1}} >
                <Typography
                  variant='h5'
                  width={'100vh'}
                  sx={{ fontSize: 20, fontFamily: 'Courier New, monospace', textShadow: '2px 2px 4px black' }}
                >
                  Please select one assistant to talk to
                </Typography>
              </Box>
              {
            isMobile &&
            <Grid container justifyContent={'center'} display={'flex'} alignItems={'center'} spacing={2}>
              <Grid item xs={8}>
                <SelectBox label="Search Assistant" name="assistant"  value={formData.assistant} onChange={handleFormChange}  sx={{backgroundColor: theme.BODY_BG, my:5}} options={assistantList} required />
              </Grid>
              <Grid item xs={4}>
                <ActionButton sx={{mb: 1}} type='submit' buttonText={isMobile? "Talk":"Talk To Assistant"} />
                
              </Grid>
            </Grid>
             
            
            }

              {
                isMobile?
                <Box sx={{
                  display: 'block',
                  justifyContent: 'center',
                  maxHeight: '50vh', // Set the desired max height
                  overflowY: 'auto', // Add a vertical scroll when content exceeds maxHeight
                }}>

                  {assistantData.map((assistant, index) => {
                  return (
                      <Box sx={{mt: 2,
                      }}  justifyContent={'center'}>
  
                        <AssistantBox key={index} number={assistant.order_number} img={assistant.image} name={assistant.name} title={assistant.title} onClick={() => {
                          setSelectedAssistant(assistant)
                          setState({voiceAssistant: true})
                        }} />
                      </Box>
                      
                    
                  )
                })}
                </Box>:

                <Grid
                  container
                  sx={{
                    mt: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    maxHeight: '40vh', // Set the desired max height
                    overflowY: 'auto', // Add a vertical scroll when content exceeds maxHeight
                  }}
                >
                {assistantData.map((assistant, index) => (
                  <Grid item xs={3} key={index}>
                    <AssistantBox
                      img={assistant.image}
                      name={assistant.name}
                      title={assistant.title}
                      number={assistant.order_number} 
                      onClick={() => {
                        setSelectedAssistant(assistant);
                        setState({ voiceAssistant: true });
                      }}
                    />
                  </Grid>
                ))}
                
                </Grid>
              }
          </Box>
          {
            !isMobile &&  <>

              <SelectBox label="Or Search Assistant" name="assistant"  value={formData.assistant} onChange={handleFormChange}  sx={{backgroundColor: theme.BODY_BG, my: 2}} options={assistantList} required />
              <ActionButton sx={{mb: 1}} type='submit' buttonText={isMobile? "Talk":"Talk To Assistant"} />

          </>
          }
          
        </Box>
      </Box>
    </Container>
)
}