import React, { useState } from 'react'

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'

import useToken from './userToken'
import userData from './userData'
import Home from './components/home/Home'
import Authentication from './components/auth/Authentication'
import { LoadingMain } from './components/custom/loading'
import Admin from './components/admin/Admin'

import { ADMIN_ROLE, USER_ROLE } from './userRoles'
import Main from './components/main/Main'
import Account from './components/main/account/Account'
import Payment from './components/main/payment/Payment'
import History from './components/main/history/History'

function App() {

  const [ loading, setLoading ] = useState({
    state: false,
    headline: "",
    paragraph: ""
  })
  const { token, removeToken, setToken, getRole } = useToken()
  const { user, removeUser, setUser } = userData()

  return (
    <div style={{ textAlign: "center", minHeight: "100vh", color: "white" }}>
    {
      loading.state? 

      <LoadingMain headline={loading.headline} paragraph={loading.paragraph} />: 

      <Router throttle={0}>
          {
                !token && token!=="" && token!== undefined?
                <Routes>
                  <Route excat path="/" element={<Home />} />
                  <Route excat path="/auth" element={<Authentication setLoading={setLoading} setToken={setToken} setUser={setUser} />} />
                  <Route excat path="/reset-password/:uid64/:resetToken" element={<Authentication resetPassword setLoading={setLoading} setToken={setToken} setUser={setUser} />} />

                  <Route path="*" element={<Navigate to="/" />} />
              </Routes>:
              getRole() === USER_ROLE?
              <Routes>
                  <Route excat path="/assistant" element={<Main user={user} token={token}  removeToken={removeToken}  setUser={setUser} removeUser={removeUser} setLoading={setLoading} />} />
                  <Route excat path="/account" element={<Account user={user} token={token}  removeToken={removeToken} removeUser={removeUser} setUser={setUser} setLoading={setLoading} />} />
                  <Route excat path="/history" element={<History user={user} token={token}  removeToken={removeToken}  setUser={setUser} removeUser={removeUser} setLoading={setLoading} />} />
                  <Route excat path="/payment" element={<Payment user={user} setUser={setUser} token={token}  removeToken={removeToken} removeUser={removeUser} setLoading={setLoading} />} />
                  <Route path="*" element={<Navigate to="/assistant" />} />
              </Routes>:
              getRole() === ADMIN_ROLE?
              <Routes>
                <Route excat path="/admin" element={<Admin user={user} token={token}  removeToken={removeToken}  setUser={setUser} removeUser={removeUser} setLoading={setLoading} />} />
                <Route path="*" element={<Navigate to="/admin" />} />
              </Routes>:  
              <Routes>
                <Route excat path="/" element={<Home />} />
                <Route path="*" element={<Navigate to="/" />} />

              </Routes>
          }
        </Router> 
    }
     
    </div>
  );
}

export default App;
