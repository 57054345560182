import React, { useState } from "react";

import { Box, Grid, Typography, useMediaQuery } from '@mui/material';

import { Container } from '@mui/material';

import ConversationBox from "./ConversationBox";
import MobileConversationBox from "./MobileConversationBox";
import Assistant from "./Assistant";
import Controls from "./Controls";
import MobileControls from "./MobileControl";
import * as theme from '../../theme'


// import { ArrowBack } from "@mui/icons-material";
// import { PrimaryActionButton } from "./Custom/Button";

export default function VoiceAssistant({user, setUser, token, removeToken, selectedAssistant, setState}) {
  const [timeData, setTimeData] = useState({
    sst_elapsed_time: null,
    assistant_elapsed_time: null,
    elevenlabs_elapsed_time: null,
    totalTime: null

  })
  const [conversation, setConversation] = useState([])
  const [isTextOnly, setIsTextOnly] = useState(false)
  const handleIsTextOnly = () => {
    setIsTextOnly(!isTextOnly);
  };
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
      <>
      
        <Container
          sx={{
            backgroundColor: theme.BODY_BG,
            display: isMobile?'block': 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 0,
            width:'100%',
            my: 3,
          }}
        >
        {
          isMobile?
          <>
            <Box sx={{ mt: 1, mb: 1,}}>
              <Assistant
                img={selectedAssistant.image}
                name={selectedAssistant.name}
                title={selectedAssistant.title}
                description={selectedAssistant.description}
              />
            </Box>
            <Box sx={{ mt: 1, mb: 1 }}>
              <MobileConversationBox conversation={conversation}  handleIsTextOnly={handleIsTextOnly} isTextOnly={isTextOnly} />
            </Box>
            <Box sx={{ mt: 1, mb: 1 }}>
              <MobileControls
                user={user}
                setUser={setUser}
                token={token}
                removeToken={removeToken}
                conversation={conversation}
                setConversation={setConversation}
                selectedAssistant={selectedAssistant}
                setState={setState}
                isTextOnly={isTextOnly} 
                setTimeData={setTimeData}
              />
            </Box>
            <Box sx={{mt: 2}}>
                {/* <h4> Time taken</h4> */}
                <p>STT: {timeData.sst_elapsed_time}, Assistant: {timeData.assistant_elapsed_time}, Elevn: {timeData.elevenlabs_elapsed_time}</p>
                <p>Total time by api:  {timeData.totalTime}</p>
              </Box>
          </>
          :
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={8} display={'flex'} alignItems={'center'}>
              <ConversationBox conversation={conversation} handleIsTextOnly={handleIsTextOnly} isTextOnly={isTextOnly}/>
            </Grid>
            <Grid item sx={{mt: 5}} xs={4}>
              <Assistant img={selectedAssistant.image} name={selectedAssistant.name} title={selectedAssistant.title} description={selectedAssistant.description} /> 
              <Controls user={user} setUser={setUser} token={token} removeToken={removeToken} conversation={conversation} setConversation={setConversation} selectedAssistant={selectedAssistant} setState={setState} isTextOnly={isTextOnly} setTimeData={setTimeData}/>
              <Box sx={{mt: 2}}>
                {/* <h4> Time taken</h4> */}
                <p>STT: {timeData.sst_elapsed_time}, Assistant: {timeData.assistant_elapsed_time}, Elevn: {timeData.elevenlabs_elapsed_time}</p>
                <p>Total time by api:  {timeData.totalTime}</p>
              </Box>
            </Grid>
          </Grid>
        }
      </Container>
    </>
  )
}