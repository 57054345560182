import { useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom';

import axios from 'axios';

import { Box, Typography } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { SubmitButton } from '../custom/button';
import { TextField } from '../custom/field';
import * as theme from '../../theme'
import logo from '../../logo.png';

import { RESET_PASSWORD } from '../../apiEndpoints';
import { Alert } from '../custom/alert';

const defaultTheme = createTheme();

export default function ResetPassword() {
 const navigate = useNavigate()
 const { uid64, resetToken } = useParams();
 const [apiLoading, setAPILoading] = useState(false);
 const [resetPassowrdFormValues, setResetPasswordFormValues] = useState({})
 const [alert, setAlert] = useState({
  open: false,
  title: "",
  message: ""
 });

 const [successAlert, setSuccessAlert] = useState({
  open: false,
  title: "",
  message: ""
 });

 const handleAlertOpen = (title, message, success=false) => {
  
  if (success)
  setSuccessAlert({
    open: true,
    title: title,
    message: message
  })
  else
  setAlert({
    open: true,
    title: title,
    message: message
  })

 };

 const handleAlertClose = (success=false) => {
  
  if (success) navigate("/auth")
  
  else setAlert({
    open: false,
    title: "",
    message: ""
  });
 };


 const handleResetPasswordFormSubmit = (event) => {
  event.preventDefault()
  
  if (resetPassowrdFormValues.password !== resetPassowrdFormValues.confirmPassword){
    handleAlertOpen("Error", "Password didn't match")
    return
  }

  setAPILoading(true)
  axios.put(
      RESET_PASSWORD, 
      {
        "password": resetPassowrdFormValues.password,
        "uid64": uid64,
        "resetToken": resetToken
      }, 
      {
          headers: { 'Content-Type': 'application/json' } 
      }
  )
  .then(response => response.data)
  .then(data => {
    setAPILoading(false)  
      setResetPasswordFormValues({
        password: '',
        confirmPassword: '',
      })
      handleAlertOpen("Success", "Password has been changed successfully.", true)
      
  })
  .catch(error => {
    setAPILoading(false)
    const errorResponse = error.response
    handleAlertOpen("Error", errorResponse!==undefined?errorResponse.data.message:error.message)

  });

 }



  const handleResetPasswordFormChange = (event) => {
    setResetPasswordFormValues((prevValues) => ({
        ...prevValues,
        [event.target.name]: event.target.value,
    }))
  }



  return (
    <>
      <Alert alert={alert} handleAlertClose={handleAlertClose} />
      <Alert alert={successAlert} handleAlertClose={() => handleAlertClose(true)} />
      {/* Desktop + Mobile version */}
      <Box sx={{ display: { xs: 'block', md: 'block' } }}>
        <Box display="flex" justifyContent="center" alignItems="center"  >
          <Box padding={5} textAlign="center"  sx={{my:2}}>
            <ThemeProvider theme={defaultTheme}>

              <Container component="main"  sx={{
                display: "flex",
                minHeight: '60vh',
              }}  id='signup' maxWidth="xs" >
                <CssBaseline />
                <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                    <img src={logo} alt="Logo" width={120} />
                  
                  <Typography component="h1" variant="h5">
                    Reset Passowrd
                  </Typography>
                  <Box component="form"  onSubmit={handleResetPasswordFormSubmit} sx={{ mt: 3, color:theme.BODY_FONT }}>
                    <Grid container spacing={2}>
                      
                    <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          name="password"
                          label="Password"
                          type="password"
                          id="password"
                          value={resetPassowrdFormValues.password}
                          onChange={handleResetPasswordFormChange} 
                        />
                      </Grid>
                      
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          name="confirmPassword"
                          label="Confirm Password"
                          type="password"
                          id="confirmPassword"
                          value={resetPassowrdFormValues.confirmPassword}
                          onChange={handleResetPasswordFormChange} 
                        />
                      </Grid>

                    </Grid>
                    <SubmitButton
                      fullWidth
                      loading={apiLoading}
                      variant="contained"
                      buttonText={"Change"}
                      sx={{my:2}}
                      
                    />
                  </Box>

                </Box>
              </Container>
              
            </ThemeProvider>
          </Box>
        </Box>
      </Box>
    </>
  ) 
}
