import React, { useEffect, useState } from 'react'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import AssistantList from './AssistantList'
import Title from './Title'
import { APILoader } from '../../custom/loading'
import { ActionButton, MobileActionButton } from '../../custom/button'

import CreateAssistant from './CreateAssistant';
import { ArrowBack } from '@mui/icons-material';
import { ASSISTANT, BUSINESS } from '../../../apiEndpoints';
import axios from 'axios';
import ViewAssistant from './ViewAssistant';
import UpdateAssistant from './UpdateAssistant';
import OptionAlert from '../../main/Custom/OptionAlert';

import * as theme from '../../../theme'
import Alert from '../../main/Custom/Alert';

export default function Assistants({user, token,  removeToken, removeUser}) {
  const [apiLoading, setAPILoading] = useState(false)
  const [state, setState] = useState({
   list: true,
   create: false,
   view: false,
   update: false,
  });
 
  const [assistantData, setAssistantData] = useState([])
  const [selectedAssistant, setSelectedAssistant] = useState({})
  const [businessOptions, setBusinessOptions] = useState([]);
  const [optionAlert, setOptionAlert] = useState({
    open: false,
    title: "",
    message: ""
   });

  const [alert, setAlert] = useState({
    open: false,
    title: "",
    message: ""
   });
  
   const handleAlertOpen = (title, message) => {
    setAlert({
      open: true,
      title: title,
      message: message
    });
   };

   const handleAlertClose = () => {
    setAlert({
      open: false,
      title: "",
      message: ""
    });
   };

  const handleOptionAlertOpen = (title, message) => {
    setOptionAlert({
      open: true,
      title: title,
      message: message
    });
   };

   const handleOptionAlertClose = () => {
    setOptionAlert({
      open: false,
      title: "",
      message: ""
    });
   };


  const loadAssistant = () => {
    setAPILoading(true)
    axios.get(ASSISTANT, { withCredentials: false, headers: {  Authorization: 'Bearer ' + token } }, )
    .then(response => response.data)
    .then(response => {
      setAPILoading(false)
      const assistants = response.data
      setAssistantData(assistants)
    })
    .catch(error => {
      setAPILoading(false)
      const errorResponse = error.response
      if (errorResponse) if (errorResponse.data.token_expired) {
        removeUser()
        removeToken()
      }

     
      }
    )
  }

  useEffect(() => {
    loadAssistant()
  }, [])

  const handleState = ({list, create, view, update}) => {
    setState({
      list: list,
      create: create,
      view: view,
      update: update,
    })
  }

  const handleAssistantDelete= (assistant) => {
    setSelectedAssistant(assistant)
    handleOptionAlertOpen("Warning", "You really want to delete " + assistant.name)
  }

  const handleDeleteAssistant = (assistantId) => {
    handleOptionAlertClose()

    setAPILoading(true)
    axios
      .delete(ASSISTANT + assistantId + "/", {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      }).then(response => response.data)
      .then(data => {
        setAPILoading(false)
        window.location.reload()
      })
      .catch(error => {
        setAPILoading(false)
        const errorResponse = error.response
        handleAlertOpen("Error", errorResponse!==undefined?errorResponse.data.message:error.message)
      });
 

  
  }

  useEffect(() => {
    // Simulating API call
    // Replace this with your actual API call
    const fetchBusinessOptions = async () => {
      try {
        const response = await axios.get(BUSINESS, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + token,
          },
        });
        const data = response.data.data;

        // Transform data to { label, value } format
        const transformedOptions = [
          { label: 'Public', value: 'public' },
          ...data.map(({ id, business_name }) => ({
            label: business_name,
            value: id,
          })),
        ];

        setBusinessOptions(transformedOptions);
      } catch (error) {
        console.error('Error fetching business options:', error);
      }
    };

    fetchBusinessOptions();
  }, []); 
  
  
    

  return (
    <div>
        <OptionAlert alert={optionAlert} handleAlertClose={handleOptionAlertClose} options={[
          {
            text: "Cancel",
            handler: handleOptionAlertClose,
          },{
            text: "Delete",
            handler: () => handleDeleteAssistant(selectedAssistant.id),
            bgcolor: theme.WARNING_BG,
            color: theme.WARNING_FONT
          }
        ]} />
        <Alert alert={alert} handleAlertClose={handleAlertClose} />

        {
            state.list?
            <>
                <Title text={"Available Assistants"} buttons={[<ActionButton onClick={() => handleState({create: true}) } cursor="pointer" buttonText={"Create"} startIcon={<AddCircleOutlineIcon />} key={1} sx={{ml:1}} />]} mobileButtons={[<MobileActionButton onClick={() => handleState({create: true})} cursor="pointer" buttonText={""} startIcon={<AddCircleOutlineIcon  />} key={1}  />]} />
                <APILoader loading={apiLoading} />
                <AssistantList  user={user} assistantData={assistantData} handleState={handleState} handleAssistantDelete={handleAssistantDelete} setAssistantData={setAssistantData} setSelectedAssistant={setSelectedAssistant} />
            </>:
            state.create?
            <>
                <Title text={"Create Assistant"} buttons={[<ActionButton onClick={() => handleState({list: true}) } cursor="pointer" buttonText={"Back to List"} startIcon={<ArrowBack />} key={1} sx={{ml:1}} />]} mobileButtons={[<MobileActionButton onClick={() => handleState({list: true})} cursor="pointer" buttonText={""} startIcon={<ArrowBack sx={{padding: 0, margin: 0}} />} key={1} />]} />
                <APILoader loading={apiLoading} />
                <CreateAssistant token={token} setAPILoading={setAPILoading} businessOptions={businessOptions} />
            </>:
            state.update?
            <>
                <Title text={"Update Assistant"} buttons={[<ActionButton onClick={() => handleState({list: true}) } cursor="pointer" buttonText={"Back to List"} startIcon={<ArrowBack />} key={1} sx={{ml:1}} />]} mobileButtons={[<MobileActionButton onClick={() => handleState({list: true})} cursor="pointer" buttonText={""} startIcon={<ArrowBack sx={{padding: 0, margin: 0}} />} key={1} />]} />
                <APILoader loading={apiLoading} />
                <UpdateAssistant token={token} setAPILoading={setAPILoading} assistantData={selectedAssistant} businessOptions={businessOptions} />
            </>:
            state.view?
            <>
              <Title text={"View Assistant"} buttons={[<ActionButton onClick={() => handleState({list: true}) } cursor="pointer" buttonText={"Back to List"} startIcon={<ArrowBack />} key={1} sx={{ml:1}} />]} mobileButtons={[<MobileActionButton onClick={() => handleState({list: true})} cursor="pointer" buttonText={""} startIcon={<ArrowBack sx={{padding: 0, margin: 0}} />} key={1} />]} />
              <ViewAssistant assistantData={selectedAssistant} businessOptions={businessOptions} />
            </>: ""
        }

           
        
    </div>
  )
}
