import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import AccountCircle from '@mui/icons-material/AccountCircle';

import logo from '../../../logo.png';
import * as theme from '../../../theme'
import { useMediaQuery } from '@mui/material';


export default function Header({removeToken, removeUser, setLoading}) {

  const navigate = useNavigate()
  const [accountMenu, setAccountMenu] = useState(null);
  const isMobile = useMediaQuery('(max-width:600px)');



  const handleAccountMenu = (event) => {
    setAccountMenu(event.currentTarget);
  };

  const handleAccountClose = () => {
    setAccountMenu(null);
  };



  const handleLogout = () => {
    handleAccountClose()
    removeToken()
    removeUser()
    navigate("/auth")
    setLoading({
      state: true,
      headline: "Logging Out",
      paragraph: "We hope to see you soon"
    }
    )
    setTimeout(() =>{
      setLoading({state:false, headline: "", paragraph: ""})
    }, 1500)
  } 

  return (
    <>
    {
      isMobile?

      <Box sx={{ flexGrow: 1 }} >
      <AppBar position="static" sx={{ backgroundColor: theme.HEADER_BG, color: theme.HEADER_FONT}}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button edge="start" color="inherit" style={{fontSize: 20}} sx={{ mr: 2 }}>
         <img src={logo} alt="Logo" width={40} style={{ marginRight: 5 }} />
        </Button>
        
          {
            <div>
              <IconButton
              onClick={handleAccountMenu}
              color="inherit"
            >
             
                <AccountCircle sx={{ width: 40, height: 40 }} />
        
              
            </IconButton>
              <Menu
              sx={{ display: { xs: 'block', md: 'none' } }}
                id="menu-appbar"
                anchorEl={accountMenu}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(accountMenu)}
                onClose={handleAccountClose}
              >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
            
          }
      </Toolbar>
    </AppBar>
    </Box>:
        <AppBar position="static" sx={{ backgroundColor: theme.HEADER_BG, color: theme.HEADER_FONT}}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button edge="start" color="inherit" style={{fontSize: 20}}> 
          <img src={logo} alt="Logo" width={60} style={{ marginRight: 5 }} />
          AI Assistant Expert - Admin Portal
          </Button>  
            
          <div>
            <IconButton
              onClick={handleAccountMenu}
              color="inherit"
            >
              <AccountCircle sx={{ width: 50, height: 50 }} />
          </IconButton>
            <Menu
            sx={{ display: { xs: 'none', md: 'block' } }}
              id="menu-appbar"
              anchorEl={accountMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(accountMenu)}
              onClose={handleAccountClose}
            >
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
            
        </Toolbar>
      </AppBar>

    }


    </>
  );
}
