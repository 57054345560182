import React, {useEffect, useRef, useState} from "react"
import { ReactMic } from 'react-mic';
import LinearProgress from '@mui/material/LinearProgress';

import {
    Container,
    Box,
    IconButton,
    Typography,
} from "@mui/material"
import axios from "axios"

import PauseIcon from '@mui/icons-material/Pause';
import { ArrowBack } from '@mui/icons-material';
import DangerousIcon from '@mui/icons-material/Dangerous';
import EditIcon from '@mui/icons-material/Edit';

import { PrimaryActionButton } from "./Custom/Button";
import { THREAD } from "../../apiEndpoints";
import Alert from "./Custom/Alert";
import theme from "../../styles/theme";
import { InstructionAlert } from "../custom/alert";



export default function MobileControls({user, setUser, token, removeToken, conversation, setConversation, selectedAssistant, setState, isTextOnly, setTimeData}) {
    const [isMobConversationStarted, setIsMobConversationStarted] = useState(false);
    const [isRecordingMob, setIsRecordingMob] = useState(false);
    const [loadingMob, setLoadingMob] = useState(false);

    const [showInstructionsMobile, setShowInstructionsMobile] = useState('true');
    useEffect(() => {
      const userPreference = localStorage.getItem('showInstructions');
    
      if (userPreference === 'false') {
        setShowInstructionsMobile('false');
      }
    }, []);


    const [alertMob, setAlertMob] = useState({
        open: false,
        message: "",
        title: "",
    });
    const threadRefMob = useRef(null);

    
    const isTextOnlyMobRef = useRef(isTextOnly)
    isTextOnlyMobRef.current = isTextOnly
    const isCoversationStartedMobRef = useRef(isMobConversationStarted)
    isCoversationStartedMobRef.current = isMobConversationStarted

    const [instructionMobileAlert, setInstructionMobileAlert] = useState({
      open: false,
      message: "",
      title: "",
    });
    const instructionAlertMessage = `
    The pulsing ring indicates the Assistant is listening. It will detect
    that you have stoped talking, and the loading indicates that
    they are processing the questions and looking for the answer.`


    let recordedBlobs = [];
    let silentBlobSize = 969
    let silentCount = 0;
    let thresholdSilentCount = 40;
    let maxMinRangeValue = 20;
  
    const onStartMob = () => {

      navigator.mediaDevices.getUserMedia({ audio: true })
      .then(() => {
        setIsRecordingMob(true);
      })
      .catch((error) => {
        setAlertMob({
          title: "Error",
          message: 'Error accessing microphone',
          open: true
        })
        console.error('Error accessing microphone:', error);
      });
    };
  
 

    const onStopMob = (recordedBlob, selectedAssistant, threadId) => {
      if (!isCoversationStartedMobRef.current) return
      
      setLoadingMob(true)

      const audioBlob = new Blob([recordedBlob.blob], { type: 'audio/wav' });
      const formData = new FormData();
      formData.append('audio', audioBlob, 'voice_input.mp3');

      const totalStartTime = performance.now();

      if (threadId){

        let additionalData = {
          assistant_id: selectedAssistant.id,
          thread_id: threadId
        }

        Object.keys(additionalData).forEach((key) => {
          formData.append(key, additionalData[key]);
        });
  
        axios.put(THREAD + threadId + "/", formData,{
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${token}`,
            },
          })
        .then(response => {
          playResponseAndUpdateConversationMob(response.data.data.response, response.data.data.conversation, response.data.data.voice_id, response.data.data.sst_elapsed_time, response.data.data.assistant_elapsed_time, totalStartTime, response.data.data.optimize_streaming_latency)
            .then(() => {
              continueConversationMob();
            })
            .catch((error) => {
                console.error('Error during conversation:', error);
            });
  
        })
        .catch(error => {
            let message
            let token_expired 
  
            try{
                message = error.response.data.message
            }catch(e){
                message = error.message
            }
  
            try{
              token_expired = error.response.data.token_expired
            }catch(e){
              token_expired = false
            }
  
            if (token_expired){
              removeToken()
            }
  
            setAlertMob({
                title: "Error",
                message: message,
                open: true
            })
  
            setIsRecordingMob(false);
            setLoadingMob(false)
                
        })

      }
      else{

        let additionalData = {
          assistant_id: JSON.stringify(selectedAssistant.id)
        }
        Object.keys(additionalData).forEach((key) => {
          formData.append(key, additionalData[key]);
        });
  
        
        axios.post(THREAD, formData,{
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${token}`,
            },
          })
        .then(response => {
            
            threadRefMob.current = response.data.data.thread_id

            setUser(response.data.data.user_data)
            playResponseAndUpdateConversationMob(response.data.data.response, response.data.data.conversation, response.data.data.voice_id,response.data.data.sst_elapsed_time, response.data.data.assistant_elapsed_time, totalStartTime, response.data.data.optimize_streaming_latency)
            
            .then(() => {
                    continueConversationMob();
            })
            .catch((error) => {
                console.error('Error during conversation:', error);
            });
  
        })
        .catch(error => {
            let message
            let token_expired 
  
            try{
                message = error.response.data.message
            }catch(e){
                message = error.message
            }
  
            try{
              token_expired = error.response.data.token_expired
            }catch(e){
              token_expired = false
            }
  
            if (token_expired){
              removeToken()
            }
  
            setAlertMob({
                title: "Error",
                message: message,
                open: true
            })
  
            setIsRecordingMob(false);
            setLoadingMob(false)
                
        })
      }

    };
  
    const onDataMob = (recordedBlob) => {
  
      // Check for silence
      if (isSilent(recordedBlob)) {
          recordedBlobs = [];
          silentCount = 0;
          setIsRecordingMob(false);
          
      }
    };
  
    const isSilent = (recordedBlob) => {
      let blobSize = recordedBlob.size
      
      if ((((silentBlobSize - maxMinRangeValue) <= recordedBlobs[recordedBlobs.length - 1]) && (recordedBlobs[recordedBlobs.length - 1] <= (silentBlobSize + maxMinRangeValue))) && (((silentBlobSize - maxMinRangeValue) <= blobSize) && (blobSize <= (silentBlobSize + maxMinRangeValue)))){
        silentCount += 1
      }
      else{
        silentCount = 0
      }
      
      recordedBlobs.push(blobSize)
  
      return silentCount === thresholdSilentCount
  
    };


    const playResponseAndUpdateConversationMob = async (textData, conversationData, voiceId, sst_elapsed_time, assistant_elapsed_time, totalStartTime, optimize_streaming_latency) => {
      console.log(sst_elapsed_time, assistant_elapsed_time, totalStartTime, "-----")
      if (isTextOnlyMobRef.current){

        setIsRecordingMob(false);
        setLoadingMob(false);
        setConversation(conversationData);
        const total_elapsed_time = performance.now() - totalStartTime;

        setTimeData({
          sst_elapsed_time: sst_elapsed_time,
          assistant_elapsed_time: assistant_elapsed_time,
          elevenlabs_elapsed_time: null,
          totalTime: parseFloat(total_elapsed_time / 1000).toFixed(2)
        })

        return false;

      }
      else{
        const startTime = performance.now();
        const url = `https://api.elevenlabs.io/v1/text-to-speech/${voiceId}?optimize_streaming_latency=${optimize_streaming_latency}`;
        const api_key = process.env.REACT_APP_ELAB_API_KEY
    
        const headers = {
          Accept: 'audio/mpeg',
          'xi-api-key': api_key,
          'Content-Type': 'application/json',
        };
        const data = {
          text: textData,
          model_id: 'eleven_turbo_v2',
          voice_settings: { stability: 0.6, similarity_boost: 0.85 },
        };
    
        try {
          const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data),
          });
    
          if (response.ok) {
            const audioBlob = await response.blob();
            const audioUrl = URL.createObjectURL(audioBlob);
            
            const audio = document.getElementById('audioPlayer');
            audio.src = audioUrl;
            const playPromise = new Promise((resolve) => {
                audio.onended = resolve;
            });

            setIsRecordingMob(false);
            setLoadingMob(false);
            setConversation(conversationData);
            const elevenlabs_elapsed_time = performance.now() - startTime;
            const total_elapsed_time = performance.now() - totalStartTime;

            setTimeData({
              sst_elapsed_time: sst_elapsed_time,
              assistant_elapsed_time: assistant_elapsed_time,
              elevenlabs_elapsed_time: parseFloat(elevenlabs_elapsed_time / 1000).toFixed(2),
              totalTime: parseFloat(total_elapsed_time / 1000).toFixed(2)
            })

            audio.play().catch(error => {
              setAlertMob({
                title: "Error",
                message: 'Audio playback failed',
                open: true
              })
                console.error('Audio playback failed:', error);
            });
            await playPromise;
          } else {
            
            setAlertMob({
              title: "Error",
              message: 'Text to speech conversion failed',
              open: true
            })
            console.error('Text to speech conversion failed');
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
    };
    

      const continueConversationMob = async () => {
        if (isCoversationStartedMobRef.current && !isTextOnlyMobRef.current)
          setIsRecordingMob(true);
        else{
          setIsMobConversationStarted(false)
          setIsRecordingMob(false)
          setLoadingMob(false)

        }
    };

    const handleAlertClose = () => {
      setAlertMob({
        open: false,
        title: "",
        message: ""
      });
     };

         
    const handleInstructionMobileAlertClose = () => {
      setInstructionMobileAlert({
        open: false,
        title: "",
        message: ""
      });
     };

    
    const startConversation = async () => {
      if (user?.token_balance <= 0){
        setAlertMob({
          open: true,
          title: "Error",
          message: "You dont have enough tokens to start a conversation, please subscribe to one of our plans."
        })
        return
      }

      if (showInstructionsMobile === 'true'){
  
        setInstructionMobileAlert({
          title: "Information",
          message: instructionAlertMessage,
          open: true
        })

      }
      else{
        
        setIsMobConversationStarted(true)  
        setIsRecordingMob(true)
      }

      };
      
      const handleOnContinueMobileClick = (dontShowAgain=false) => {
        setShowInstructionsMobile(!dontShowAgain);
        localStorage.setItem('showInstructions', !dontShowAgain.toString());
      
        handleInstructionMobileAlertClose()

        setIsMobConversationStarted(true)  
        setIsRecordingMob(true)
  
        };
      
        const handleMobPause = () => {          
          setIsMobConversationStarted(false)
          setIsRecordingMob(false)
          setLoadingMob(false)
  
        };

      
      function stopConversation() {
        window.location.reload()
      }

      function handleSelectAssistant() {
        setState({
          choose: true
        })
      }

return (
    <Container>
        <Alert alert={alertMob} handleAlertClose={handleAlertClose} />
        <InstructionAlert alert={instructionMobileAlert} handleAlertClose={handleInstructionMobileAlertClose} onContinueClick={handleOnContinueMobileClick} />

        <Box >
              <ReactMic
                record={isRecordingMob}
                className={isRecordingMob? "sound-wave": "sound-wave-hidden"}
                onStop={(e) => {
                  onStopMob(e, selectedAssistant, threadRefMob.current);
                }}
                onData={onDataMob}
                onStart={onStartMob}
                strokeColor={theme.button.primary.text}
                backgroundColor={theme.button.primary.bg}
              />
                          <Typography sx={{mb:2, fontSize: {xs: 12, md: 12, margin: 0, padding:0}}} variant="p"> Click the 'Start' button when you are ready to ask questions.</Typography>
           
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>

                <PrimaryActionButton disabled={isMobConversationStarted}  onClick={() => startConversation()}>
                    {
                      isRecordingMob?
                      
                      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        Recording
                        <img src={require("../../assets/img/recording162.gif")} 
                        style={{
                          width: '24px', 
                          height: '24px',
                          marginLeft: '20px'
                        }} alt="Recording" />
                      </Box>:
                      loadingMob?

                      <Box sx={{ width: '100%', m: 1,  }}>
                        <LinearProgress sx={{ color: theme.PRIMARY_BUTTON_LOADER }} />
                      </Box>:
                      isMobConversationStarted?
                      "Speaking":
                      "Start"
                    }
                </PrimaryActionButton>
                <IconButton
                  onClick={stopConversation}
                  disabled={!isMobConversationStarted}
                >
                  <DangerousIcon sx={{ color: isMobConversationStarted? theme.button.danger.bg: 'grey', fontSize: '2rem' }} />
                </IconButton>


                <IconButton
                  onClick={handleMobPause}
                  disabled={!isMobConversationStarted}
                >
                  <PauseIcon sx={{ color: theme.button.primary.bg, fontSize: '2rem' }} />
                </IconButton>

                <IconButton
                  onClick={handleSelectAssistant}
                  disabled={isMobConversationStarted}
                >
                  <ArrowBack sx={{ color: theme.button.primary.bg, fontSize: '2rem' }} />
                </IconButton>

                <p  />
                <audio id="audioPlayer" hidden controls />
            </Box>
        </Box>
    </Container>
    
)
}
