import React, { useEffect, useState } from 'react'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { APILoader } from '../../custom/loading'
import { ActionButton, MobileActionButton } from '../../custom/button'

import { ArrowBack } from '@mui/icons-material';
import axios from 'axios';
import OptionAlert from '../Custom/OptionAlert';

import * as theme from '../../../theme'
import Alert from '../Custom/Alert';
import ConversationList from './ConversationList';
import { THREAD } from '../../../apiEndpoints';
import Title from '../Title';
import { Box } from '@mui/material';
import Footer from '../../footer/Footer';
import Header from '../../header/Header';
import ViewConversation from './ViewConversation';
import { useNavigate } from 'react-router-dom';

export default function History({user, token,  removeToken, setUser, removeUser,  setLoading}) {
  const [apiLoading, setAPILoading] = useState(false)
  const [state, setState] = useState({
   list: true,
   view: false,
  });
 
  const [threadData, setThreadData] = useState([])
  const [selectedThread, setSelectedThread] = useState({})
  const [optionAlert, setOptionAlert] = useState({
    open: false,
    title: "",
    message: ""
   });

  const [alert, setAlert] = useState({
    open: false,
    title: "",
    message: ""
   });
   const navigate = useNavigate()
  
   const handleAlertOpen = (title, message) => {
    setAlert({
      open: true,
      title: title,
      message: message
    });
   };

   const handleAlertClose = () => {
    setAlert({
      open: false,
      title: "",
      message: ""
    });
   };

  const handleOptionAlertOpen = (title, message) => {
    setOptionAlert({
      open: true,
      title: title,
      message: message
    });
   };

   const handleOptionAlertClose = () => {
    setOptionAlert({
      open: false,
      title: "",
      message: ""
    });
   };


  const loadThreads = () => {
    setAPILoading(true)
    axios.get(THREAD, { withCredentials: false, headers: {  Authorization: 'Bearer ' + token } }, )
    .then(response => response.data)
    .then(response => {
      setAPILoading(false)
      const assistants = response.data
      setThreadData(assistants)
    })
    .catch(error => {
      setAPILoading(false)
      const errorResponse = error.response
      if (errorResponse) if (errorResponse.data.token_expired) {
        removeUser()
        removeToken()
      }

     
      }
    )
  }

  useEffect(() => {
    loadThreads()
  }, [])

  const handleState = ({list, view}) => {
    setState({
      list: list,
      view: view,
    })
  }

  const handleThreadDelete= (thread) => {
    setSelectedThread(thread)
    handleOptionAlertOpen("Warning", "You really want to delete this conversation")
  }

  const handleDeleteThread = (threadId) => {
    handleOptionAlertClose()

    setAPILoading(true)
    axios
      .delete(THREAD + threadId + "/", {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      }).then(response => response.data)
      .then(data => {
        setAPILoading(false)
        loadThreads()
      })
      .catch(error => {
        setAPILoading(false)
        const errorResponse = error.response
        handleAlertOpen("Error", errorResponse!==undefined?errorResponse.data.message:error.message)
      });
  }  

  return (
    <div style={{ display: 'flex', flexDirection: 'column'}}>
        <OptionAlert alert={optionAlert} handleAlertClose={handleOptionAlertClose} options={[
          {
            text: "Cancel",
            handler: handleOptionAlertClose,
          },{
            text: "Delete",
            handler: () => handleDeleteThread(selectedThread.id),
            bgcolor: theme.WARNING_BG,
            color: theme.WARNING_FONT
          }
        ]} />
        <Alert alert={alert} handleAlertClose={handleAlertClose} />

        <Box >
          <Header user={user} type='assistant' removeToken={removeToken} removeUser={removeUser} setLoading={setLoading} />
        </Box>
        <Box sx={{backgroundColor: theme.BODY_BG, color: theme.BODY_FONT, padding: 4, minHeight: '78.5vh' }}>
          {
              state.list?
              <>
                  <Title text={"Available Conversations"} buttons={[<ActionButton onClick={() => navigate("/assistant") } cursor="pointer" buttonText={"Back to Assistants"} startIcon={<ArrowBack />} key={1} sx={{ml:1}} />]} mobileButtons={[<MobileActionButton onClick={() => navigate("/assistant")} cursor="pointer" buttonText={""} startIcon={<ArrowBack  />} key={1}  />]} />
                  <APILoader loading={apiLoading} />
                  <ConversationList  user={user} threadData={threadData} handleState={handleState} handleThreadDelete={handleThreadDelete} setThreadData={setThreadData} setSelectedThread={setSelectedThread} />
              </>:
              state.view?
              <>
                <Title text={"View Conversation"} buttons={[<ActionButton onClick={() => handleState({list: true}) } cursor="pointer" buttonText={"Back to List"} startIcon={<ArrowBack />} key={1} sx={{ml:1}} />]} mobileButtons={[<MobileActionButton onClick={() => handleState({list: true})} cursor="pointer" buttonText={""} startIcon={<ArrowBack sx={{padding: 0, margin: 0}} />} key={1} />]} />
                <ViewConversation conversation={selectedThread.conversation} assistant={selectedThread.assistant} />
              </>: ""
          }
        </Box>
        <Box>
          <Footer disclaimer />
        </Box>
        

           
        
    </div>
  )
}
