import React, {useEffect, useState} from 'react';
import { Grid, Box, Container, Typography, useMediaQuery } from '@mui/material'

import axios from 'axios';

import * as theme from '../../../theme'
import Alert from '../../main/Custom/Alert';
import { MainButton } from '../../custom/button';
// import { SelectBox } from '../../custom/select';
import { IntegerTextField, TextField } from '../../custom/field';
import { ASSISTANT } from '../../../apiEndpoints';
import TextArea from '../../custom/textarea/TextArea';
// import { chatgptModels } from '../constants/options'; 

import { InputField } from '../../custom/input';
import { SelectBox } from '../../custom/select';


export default function UpdateAssistant({token, setAPILoading, assistantData, businessOptions}) {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState(assistantData)

  const [alert, setAlert] = useState({
    open: false,
    title: "",
    message: ""
   });
   const handleAlertOpen = (title, message) => {
    setAlert({
      open: true,
      title: title,
      message: message
    });
   };

   const handleAlertClose = () => {
    setAlert({
      open: false,
      title: "",
      message: ""
    });
   };


  const handleFormChange = (event) => {
    console.log(event.target.value)
    setFormData((prevValues) => ({
        ...prevValues,
        [event.target.name]: event.target.value,
    }))
  }

  const getUpdatedData = (newData) => {
    const updatedData = {};
    for (const key in assistantData) {
      if (assistantData.hasOwnProperty(key) && assistantData[key] !== newData[key]) {
        updatedData[key] = newData[key];
      }
    }
  
    return updatedData;
  };

  function extractFileName(url) {
    const segments = url.split('/');
    const fileName = segments[segments.length - 1];
    return fileName;
  }

  const handleUpdateSubmit = (event) => {
    event.preventDefault();

    var currentdate = new Date();
    var datetime =
      currentdate.getFullYear() +
      ',' +
      (currentdate.getMonth() + 1) +
      ',' +
      currentdate.getDate() +
      ',' +
      currentdate.getHours() +
      ',' +
      currentdate.getMinutes() +
      ',' +
      currentdate.getSeconds();

    const updateFormData = getUpdatedData(formData)
    if (Object.keys(updateFormData).length === 0) {
      setAlert({
        open: true,
        title: "Warning",
        message: "Nothing was changed"
      })
      return true
    }

    setLoading(true);
    const bodyData = new FormData();
    Object.keys(formData).forEach((key) => {
      bodyData.append(key, formData[key]);
    });

    if (formData.files) {
      const fileInput = document.querySelector('input[name="files"]');
      const files = fileInput.files;

      for (let i = 0; i < files.length; i++) {
        bodyData.append('files', files[i]);
      }
    }

    if (formData.image) {
      const imageInput = document.querySelector('input[name="image"]');
      const imageFile = imageInput.files[0];
  
      if (imageFile) {
        bodyData.append('image', imageFile);
      }
    }

    bodyData.append('updated_at', datetime);
    

    axios
      .put(ASSISTANT + formData.id + "/", bodyData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + token,
        },
      }).then(response => response.data)
      .then(data => {
        setLoading(false)
        window.location.reload()
      })
      .catch(error => {
        setLoading(false)
        const errorResponse = error.response
        handleAlertOpen("Error", errorResponse!==undefined?errorResponse.data.message:error.message)
      });
 

  
  }

  

  return (
      
        <Container >
          <Alert alert={alert} handleAlertClose={handleAlertClose} />

            {
              isMobile?

            <Container component="main"  sx={{
                    display: "flex",
                    minHeight: '60vh',
                    padding:2
                  }}  id='signup' maxWidth="md" >
              <Box display={'inline-flex'} alignItems={'center'} justifyContent={'center'} sx={{  backgroundColor: theme.BODY_BG, color: theme.BODY_FONT, width: '40vh'}}> 
                <Box component="form"  onSubmit={handleUpdateSubmit} sx={{ color:theme.BODY_FONT }}>
                  <Grid container spacing={2}>
                  <Grid item xs={12} display={'flex'} justifyContent={'center'} sx={{py: 2}}>
                      <img width={'50%'}  alt='assistant' src={assistantData.image} />
                  </Grid>
                  <Grid item xs={12}  >
                    <SelectBox label="Business" name="business" value={formData.business} onChange={handleFormChange}  sx={{backgroundColor: theme.BODY_BG}} options={businessOptions}  required />
                  </Grid>
                  <Grid item xs={12}  >
                    <TextField sx={{width: '100%'}} name="name" label="Name"
                        value={formData.name} onChange={handleFormChange}  required  />
                  </Grid>
                  <Grid item xs={6}  >
                    <TextField sx={{width: '100%'}} name="title" label="Title"
                        value={formData.title} onChange={handleFormChange}  required  />
                  </Grid>

                  <Grid item xs={6}  >
                    <IntegerTextField sx={{width: '100%'}} name="order_number" label="Order Number"
                        value={formData.order_number} onChange={handleFormChange}  />
                  </Grid>
                  <Grid item xs={12} >
                    <TextArea label="Description" name="description" value={formData.description} onChange={handleFormChange}  sx={{backgroundColor: theme.BODY_BG}} required />
                  </Grid>
                  <Grid item xs={12} >
                    <TextArea label="Instructions" name="instructions" value={formData.instructions} onChange={handleFormChange}  sx={{backgroundColor: theme.BODY_BG}} required />
                  </Grid>
                  <Grid item xs={12}  >
                    <TextField sx={{width: '100%'}} name="voice_id" label="Voice ID"
                        value={formData.voice_id} onChange={handleFormChange} required    />
                  </Grid>
                  {/* <Grid item xs={6} >
                    <SelectBox label="Model" name="model"  value={formData.model} onChange={handleFormChange}  sx={{backgroundColor: theme.BODY_BG}} options={chatgptModels} required />
                  </Grid> */}
                  <Grid item xs={12}>
                    <InputField accept={'image/*'}  sx={{width: '100%'}} onChange={handleFormChange}  label="Image" name="image"/>
                  </Grid>
                  <Grid item xs={12}>
                    <InputField sx={{width: '100%'}} label="Files" onChange={handleFormChange}   name="files" multiple={true} />
                  </Grid>
                  {
                      assistantData.files?.length !== 0 &&
                      <>
                      <Grid item xs={12}>
                        <Typography variant='h6' textAlign={'left'}>
                        Existing Files: 
                        </Typography>
                        <br/>
                        {
                          assistantData.files?.map((file, index) => (
                              <Box display={'flex'}>
                                {index + 1}. <a href={file} rel="noreferrer"  target='_blank' style={{marginRight: 10, marginLeft: 10, textDecoration: 'none', color: 'wheat'}} key={index}>{extractFileName(file)}</a>
                              </Box>
                            )
                          )
                        }
                      </Grid>
                      </>
                    } 
                  <Grid item sx={{mt:3}} xs={12}>
                    <MainButton type='submit' buttonText="Update Assistant" loading={loading} />
                  </Grid>  
                  
                    
                  </Grid>
                </Box>
              </Box>
            </Container>
        
            
            :
          <Grid container display={'flex'} justifyContent={'center'} sx={{px: 10}}>

            <Box sx={{ display: { xs: 'none', md: 'flex',  } }}>
              <Grid item xs={4} sx={{py: 5}}>
                <Box >
                  <img width={'100%'} alt='assistant' src={assistantData.image} />
                </Box>
                {
                        assistantData.files?.length !== 0 &&
                        <>
                        <Box>
                          <Typography variant='h6' textAlign={'left'}>
                          Files: 
                          </Typography>
                          <br/>
                          {
                            assistantData.files?.map((file, index) => (
                                <Box display={'flex'}>
                                  {index + 1}. <a href={file} rel="noreferrer" target='_blank' style={{marginRight: 10, marginLeft: 10, textDecoration: 'none', color: 'wheat'}} key={index}>{extractFileName(file)}</a>
                                </Box>
                              )
                            )
                          }
                        </Box>
                        </>
                      } 
              </Grid>
              <Grid item xs={8} sx={{ px:5, py:5 }} >
                <Box component="form"  onSubmit={handleUpdateSubmit} >
                  <Grid container spacing={2}>
                  <Grid item xs={12}  >
                    <SelectBox label="Business" name="business" value={formData.business} onChange={handleFormChange}  sx={{backgroundColor: theme.BODY_BG}} options={businessOptions}  required />
                  </Grid>
                    <Grid item xs={12}  >
                      <TextField sx={{width: '100%'}} name="name" label="Name"
                          value={formData.name} onChange={handleFormChange}  required  />
                    </Grid>
                    <Grid item xs={6}  >
                      <TextField sx={{width: '100%'}} name="title" label="Title"
                          value={formData.title} onChange={handleFormChange}  required  />
                    </Grid>

                    <Grid item xs={6}  >
                      <IntegerTextField sx={{width: '100%'}} name="order_number" label="Order Number"
                          value={formData.order_number} onChange={handleFormChange}  />
                    </Grid>
                    <Grid item xs={12} >
                      <TextArea label="Description" name="description" value={formData.description} onChange={handleFormChange}  sx={{backgroundColor: theme.BODY_BG}} required />
                    </Grid>
                    <Grid item xs={12} >
                      <TextArea label="Instructions" name="instructions" value={formData.instructions} onChange={handleFormChange}  sx={{backgroundColor: theme.BODY_BG}} required />
                    </Grid>
                    <Grid item xs={12}  >
                      <TextField sx={{width: '100%'}} name="voice_id" label="Voice ID"
                          value={formData.voice_id} onChange={handleFormChange} required    />
                    </Grid>
                    {/* <Grid item xs={6} >
                      <SelectBox label="Model" name="model"  value={formData.model} onChange={handleFormChange}  sx={{backgroundColor: theme.BODY_BG}} options={chatgptModels} required />
                    </Grid> */}
                    <Grid item xs={6}>
                      <InputField accept={'image/*'}  sx={{width: '100%'}} onChange={handleFormChange}  label="Image" name="image"/>
                    </Grid>
                    <Grid item xs={6}>
                      <InputField sx={{width: '100%'}} label="Files" onChange={handleFormChange}   name="files" multiple={true} />
                    </Grid>
                    <Grid item sx={{mt:3}} xs={12}>
                      <MainButton type='submit' buttonText="Update Assistant" loading={loading} />
                    </Grid>  
                    
                  </Grid>
                </Box>
              </Grid>
              
            </Box>
            </Grid>
            }

  
        </Container>
   
           
  );
}