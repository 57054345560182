import {useState} from 'react';
import PropTypes from 'prop-types';


import { Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import * as theme from '../../../theme'

function TablePaginationActions(props) {
  const muiTheme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        sx={{color: theme.TABLE_FONT }}
      >
        {muiTheme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        sx={{color: theme.TABLE_FONT }}
      >
        {muiTheme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        sx={{color: theme.TABLE_FONT }}
      >
        {muiTheme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        sx={{color: theme.TABLE_FONT }}
      >
        {muiTheme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(threadData) {
  let rows = []
  for (let row in threadData){
    const { id, created_at, name, assistant } = threadData[row]
    rows.push({id, created_at: formatDate(created_at), name, assistant})
  }
  return rows
}

const formatDate = (dateString) => {
  const dateTime = new Date(dateString)
  const date = dateTime.toISOString().slice(0, 10);
  const time = dateTime.toISOString().slice(11, 19).replace('T', ' ');

  return date + " " + time
};


export default function ConversationList({user, handleState, handleThreadDelete, threadData, setSelectedThread}) {
  const isMobile = useMediaQuery('(max-width:600px)');
  const rows = createData(threadData)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);  
  const [searchQuery, setSearchQuery] = useState('');

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleThreadView = (assistant) => {
    setSelectedThread(assistant)
    handleState({view: true})
  }
  const handleAssistantUpdate = (assistant) => {
    setSelectedThread(assistant)
    handleState({update: true})
  }

  
  const filteredRows = rows.filter((row) =>
    row.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
    <TableContainer component={Paper} sx={{borderRadius:3,
      backgroundColor: theme.BODY_BG
    }}>
      
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          px: 5,
          pt:5,
          mx: 5,
          py:2,
          backgroundColor: theme.TABLE_BG,
          borderRadius: 5
        }}
      >
        <SearchIcon sx={{ mr: '8px', color: theme.TABLE_FONT }} />

        <InputBase
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearchChange}
          sx={{
            color: theme.TABLE_FONT,
          }}
        />

      </Box>

      {
        isMobile?
        <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          pt:5,
          my: 1,
          mx: 5,
          py:2,
          borderRadius: 5,
        }}
      >

        <Table sx={{ maxWidth: 60, 
          borderRadius: 5,
          backgroundColor: theme.TABLE_BG, 
          }} aria-label="custom pagination table">
          {
            filteredRows.length > 0?
            <>
              <TableBody sx={{ color: theme.TABLE_FONT, py:3,  }}>
                {(rowsPerPage > 0
                  ? filteredRows.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : filteredRows
                ).map((row) => (
                  <TableRow key={row.created_at}>
                    <TableCell sx={{ color: theme.TABLE_FONT,  overflow: 'hidden', whiteSpace:'nowrap',  textOverflow: 'ellipsis' }} align="left">
                      {row.created_at}
                      <br/>
                      
                      <Typography sx={{mt:1}} onClick={() => handleThreadView(threadData.find(obj => obj.id === row.id))} style={{ textDecoration: 'none', cursor: 'pointer', color: theme.TABLE_FONT, maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {row.name} 
                      <br/>
                        
                        {row.assistant.name + ": " + row.assistant.title}
                      </Typography>
                    </TableCell>

                    <TableCell sx={{ color: theme.TABLE_FONT, }} align="left">
                      <IconButton
                        color="inherit"
                        onClick={() => handleThreadView(threadData.find((obj) => obj.id === row.id))}
                      >
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton 
                      color="inherit"
                      onClick={() => handleThreadDelete(threadData.find((obj) => obj.id === row.id))}
                    >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                    
                  </TableRow>


                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell sx={{color: theme.TABLE_FONT, }} colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
              <TablePagination
                  rowsPerPageOptions={[]}
                  colSpan={2}
                  count={filteredRows.length}
                  rowsPerPage={5}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    sx: {
                      '& .MuiSelect-icon': {
                        color: 'white',
                      },
                    },
                  }}
                  sx={{ color: theme.TABLE_FONT, border: 'none' }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  labelRowsPerPage={null} // Remove "Rows per page" label
                  labelDisplayedRows={({ from, to, count }) => null}
                />

              </TableFooter>
            </>:
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
              <IconButton sx={{ color: theme.TABLE_FONT,  py:5, fontSize: 15 }}>
                  No Conversation Found!
              </IconButton>
            </Box>
          }
          
        </Table>
      </Box>:
      <Box
      sx={{
        px: 5,
        pt:2,
        mx: 5,
        my:2,
        backgroundColor: theme.TABLE_BG,
        borderRadius: 5,display: { xs: 'none', md: 'block',  },
      }}
    >

    <Table sx={{ minWidth: 500, 
        backgroundColor: theme.TABLE_BG, }} aria-label="custom pagination table">
      {
        filteredRows.length > 0?
        <>
          <TableBody sx={{ color: theme.TABLE_FONT, py:3  }}>
            {(rowsPerPage > 0
              ? filteredRows.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : filteredRows
            ).map((row) => (
              <TableRow key={row.created_at}>
              <TableCell  sx={{color: theme.TABLE_FONT}} align="left">
                {row.created_at}
              </TableCell>
                <TableCell  sx={{color: theme.TABLE_FONT}} align="left">
                  {row.name}
                </TableCell>
                <TableCell sx={{ color: theme.TABLE_FONT}} scope="left">                    
                    {row.assistant.name + ": " + row.assistant.title}
                </TableCell>
                <TableCell sx={{ color: theme.TABLE_FONT}} align="left">
                  <IconButton
                    color="inherit"
                    onClick={() => handleThreadView(threadData.find((obj) => obj.id === row.id))}
                  >
                    <VisibilityIcon />
                  </IconButton>

                  <IconButton 
                    color="inherit"
                    onClick={() => handleThreadDelete(threadData.find((obj) => obj.id === row.id))}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell sx={{color: theme.TABLE_FONT, }} colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={5}
              
              count={filteredRows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                sx: {
                  '& .MuiSelect-icon': {
                    color: 'white',
                  },
                },
              }}
              sx={{color: theme.TABLE_FONT, border: 'none' }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableFooter>
        </>:
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
          <IconButton sx={{ color: theme.TABLE_FONT,  py:5  }}>
              No Conversation Found!
          </IconButton>
        </Box>
      }
      
    </Table>

    </Box>
      }
      



    </TableContainer>
    </>
  );
}