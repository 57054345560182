import React from 'react';
import { Grid, Box, Container, useMediaQuery } from '@mui/material'


import ConversationBox from '../ConversationBox';
import MobileConversationBox from '../MobileConversationBox';
import Assistant from '../Assistant';



export default function ViewConversation({conversation, assistant}) {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
      
        <>
        {
          isMobile?

          <Container
            sx={{
              minHeight: '50vh', // Set maximum height
              display: 'block',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box sx={{ mt: 1, mb: 1,}}>
              <Assistant
                img={assistant.image}
                name={assistant.name}
                title={assistant.title}
              />
            </Box>
            <Box sx={{ mt: 1, mb: 1 }}>
              <MobileConversationBox conversation={conversation} />
            </Box>

          </Container>
:
          <Container
              sx={{
                minHeight: '50vh',
                display: 'flex',

                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={8}>

                  <ConversationBox conversation={conversation}/>
                </Grid>
                <Grid item xs={4}>
                  <Assistant img={assistant.image} name={assistant.name} title={assistant.title} /> 
                </Grid>
              </Grid>
          </Container>

      }
        </>
   
           
  );
}