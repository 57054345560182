import React from "react";
import { styled } from '@mui/system';
import {
  Button,
} from "@mui/material";


const StyledAssistantContainer = styled('div')({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  cursor: 'pointer', 
})

const AssistantImage = styled('img')({
  width: '70%', // Adjust the percentage as needed
  height: 'auto', // Automatically adjusts the height to maintain the aspect ratio
  maxWidth: '166px', // Set a maximum width if necessary
  maxHeight: '188px', // Set a maximum height if necessary
  borderRadius: '20px',
});

export default function AssistantBox({img, number, name, title, onClick}){
  return (
    <StyledAssistantContainer onClick={onClick}>
      <AssistantImage alt="Assistant" src={img} />
      <Button edge="start" color="inherit" style={{fontSize: 12}}>
      {/* <strong>{number? `${number}.`: ``} {title} : </strong> {name} */}
      <strong>{title} : </strong> {name}
      </Button>
    </StyledAssistantContainer>
  );
};

