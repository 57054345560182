import React, { useState, useEffect } from 'react'

import { useNavigate } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import Header from '../../header/Header'
import Footer from '../../footer/Footer'

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import Title from '../Title'

import { ActionButton, MobileActionButton } from '../../custom/button'
import * as theme from '../../../theme'
import { APILoader } from '../../custom/loading'
import Subscriptions from './Subscriptions'
import { PRODUCT } from '../../../apiEndpoints'
import axios from 'axios'
import TypewriterComponent from 'typewriter-effect'



export default function Payment({user, token, removeToken, removeUser, setUser,setLoading}) {
  const navigate = useNavigate()
  const [statusMessage, setStatusMessage] = useState()
  const [apiLoading, setAPILoading] = useState(false)
  const [products, setProducts] = useState([])

  const loadProducts = () => {
    setAPILoading(true)

    axios.get(PRODUCT, { withCredentials: false, headers: {  Authorization: 'Bearer ' + token } }, )
    .then(response => response.data.data)
    .then(data => {
      setAPILoading(false)
      setProducts(data.products)
      setUser(data.user)
    })
    .catch(error => {
      setAPILoading(false)
      const errorResponse = error.response
      if (errorResponse) if (errorResponse.data.token_expired) {
        removeUser()
        removeToken()
      }

      }
    )
  }

  // Read all available products
  useEffect(() => {
    loadProducts()
    const query = new URLSearchParams(window.location.search)
    if (query.get('success')) {
      setStatusMessage({success: true, message: "Congratulations! Your subscription has been successfully completed. You will receive an email shortly.", updating:true})
      setTimeout(() => {
        loadProducts()
        setStatusMessage({success: true, message: "Congratulations! Your subscription has been successfully completed. You will receive an email shortly.", updating: false})
      }, 6000)
    }
    if (query.get('error')) setStatusMessage({success: false, message: "Apologies, but you are unable to subscribe to this plan."})
  }, [])
  
  const handleReturn = () => {
    navigate("/assistant")
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header user={user}  type='assistant' removeToken={removeToken} removeUser={removeUser} setLoading={setLoading} />
      <Box sx={{ flexGrow: 1, backgroundColor: theme.BODY_BG, color: theme.BODY_FONT, pb: 5}} >
        {
          statusMessage?
          <Box sx={{my:10}}>
            {
              statusMessage.success?
              <CheckCircleOutlineIcon sx={{width:200, height:200, color: theme.SUCCESS_BG}}/>:
              <WarningAmberIcon sx={{width:200, height:200, color: theme.WARNING_FONT}}/>
            }
            <Typography variant='h4'>
            {statusMessage.message}
            </Typography>
            <Typography sx={{my:3}} variant='h5'>
              {
                statusMessage.success?
                <>
                  {
                    statusMessage.updating?
                    <>
                      Updating data please wait
                      <TypewriterComponent  
                      options={
                        {
                          autoStart: true,
                          loop: true,
                          delay: 50,
                          strings: ["..."],
                        }
                      } />
                    </>:
                    <ActionButton buttonText={"Go back to assistants page"} startIcon={<ArrowBackIcon />} onClick={() => navigate("/assistant")}/>
                  }
                </>:
                <ActionButton buttonText={"Go back to assistants page"} startIcon={<ArrowBackIcon />} onClick={() => navigate("/assistant")}/>
              }
              
            </Typography>
          </Box>:
          <>
            <Title text={"Subscription"} buttons={[<ActionButton onClick={handleReturn} cursor="pointer" buttonText={"Return"} startIcon={<ArrowBackIcon />} key={1} sx={{ml:1}} />]} mobileButtons={[<MobileActionButton onClick={handleReturn} cursor="pointer" buttonText={"Return"} startIcon={<ArrowBackIcon />} key={1} sx={{ml:1}} />]} />
            <APILoader loading={apiLoading} />
            <Subscriptions user={user} setUser={setUser} token={token} setLoading={setAPILoading} products={products} />
          </>
        }
 
      </Box>  
      <Footer disclaimer />
    </div>
  )
}