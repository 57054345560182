import React, {useEffect, useRef, useState} from "react"
import { ReactMic } from 'react-mic';

import {
    Container,
    Box,
    Grid,
    Typography,
} from "@mui/material"
import axios from "axios"
import { ArrowBack } from '@mui/icons-material';
import PauseIcon from '@mui/icons-material/Pause';

import { PrimaryActionButton } from "./Custom/Button";
import { THREAD } from "../../apiEndpoints";
import Alert from "./Custom/Alert";
import theme from "../../styles/theme";
import { InstructionAlert } from "../custom/alert";

export default function Controls({user, setUser, token, removeToken, conversation, setConversation, selectedAssistant, setState, isTextOnly, setTimeData}) {
    const [isConversationStarted, setIsConversationStarted] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [loading, setLoading] = useState(false);

    const [showInstructions, setShowInstructions] = useState('true');
    useEffect(() => {
      const userPreference = localStorage.getItem('showInstructions');
    
      if (userPreference === 'false') {
        setShowInstructions('false');
      }
    }, []);

    const [alert, setAlert] = useState({
        open: false,
        message: "",
        title: "",
    });
    const [instructionAlert, setInstructionAlert] = useState({
      open: false,
      message: "",
      title: "",
    });
    const instructionAlertMessage = `
    The pulsing ring indicates the Assistant is listening. It will detect
    that you have stoped talking, and the rotating balls indicates that
    they are processing the questions and looking for the answer.`

    
    const isTextOnlyRef = useRef(isTextOnly)
    isTextOnlyRef.current = isTextOnly
    const isCoversationStartedRef = useRef(isConversationStarted)
    isCoversationStartedRef.current = isConversationStarted
    const threadRef = useRef(null);

    let recordedBlobs = [];
    let silentBlobSize = 969
    let silentCount = 0;
    let thresholdSilentCount = 40;
    let maxMinRangeValue = 20;


  
    const onStart = () => {

      navigator.mediaDevices.getUserMedia({ audio: true })
      .then(() => {
        setIsRecording(true);
      })
      .catch((error) => {
        setAlert({
          title: "Error",
          message: 'Error accessing microphone',
          open: true
        })
        console.error('Error accessing microphone:', error);
      });
    };
  
    const onStop = (recordedBlob, selectedAssistant, threadId) => {
      if (!isCoversationStartedRef.current) return
      setLoading(true)

      const audioBlob = new Blob([recordedBlob.blob], { type: 'audio/wav' });
      const formData = new FormData();
      formData.append('audio', audioBlob, 'voice_input.mp3');

      const totalStartTime = performance.now();

      if (threadId){
        
        let additionalData = {
          assistant_id: selectedAssistant.id,
          thread_id: threadId
        }

        Object.keys(additionalData).forEach((key) => {
          formData.append(key, additionalData[key]);
        });
  
        axios.put(THREAD + threadId + "/", formData,{
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${token}`,
            },
          })
        .then(response => {
          playResponseAndUpdateConversation(response.data.data.response, response.data.data.conversation, response.data.data.voice_id, response.data.data.sst_elapsed_time, response.data.data.assistant_elapsed_time, totalStartTime, response.data.data.optimize_streaming_latency)
            .then(() => {
              continueConversation();
            })
            .catch((error) => {
                console.error('Error during conversation:', error);
            });
  
        })
        .catch(error => {
            let message
            let token_expired 
  
            try{
                message = error.response.data.message
            }catch(e){
                message = error.message
            }
  
            try{
              token_expired = error.response.data.token_expired
            }catch(e){
              token_expired = false
            }
  
            if (token_expired){
              removeToken()
            }
  
            setAlert({
                title: "Error",
                message: message,
                open: true
            })
  
            setIsRecording(false);
            setLoading(false)
                
        })

      }
      else{

        let additionalData = {
          assistant_id: JSON.stringify(selectedAssistant.id)
        }
        Object.keys(additionalData).forEach((key) => {
          formData.append(key, additionalData[key]);
        });
  
        
        axios.post(THREAD, formData,{
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${token}`,
            },
          })
        .then(response => {
            
            threadRef.current = response.data.data.thread_id
            setUser(response.data.data.user_data)
            playResponseAndUpdateConversation(response.data.data.response, response.data.data.conversation, response.data.data.voice_id, response.data.data.sst_elapsed_time, response.data.data.assistant_elapsed_time, totalStartTime,response.data.data.optimize_streaming_latency)
            
            .then(() => {
                    continueConversation();
            })
            .catch((error) => {
                console.error('Error during conversation:', error);
            });
  
        })
        .catch(error => {
            let message
            let token_expired 
  
            try{
                message = error.response.data.message
            }catch(e){
                message = error.message
            }
  
            try{
              token_expired = error.response.data.token_expired
            }catch(e){
              token_expired = false
            }
  
            if (token_expired){
              removeToken()
            }
  
            setAlert({
                title: "Error",
                message: message,
                open: true
            })
  
            setIsRecording(false);
            setLoading(false)
                
        })
      }

    };
  
    const onData = (recordedBlob) => {
  
      // Check for silence
      if (isSilent(recordedBlob)) {
          recordedBlobs = [];
          silentCount = 0;
          setIsRecording(false);
          
      }
    };
  
    const isSilent = (recordedBlob) => {
      let blobSize = recordedBlob.size
      
      if ((((silentBlobSize - maxMinRangeValue) <= recordedBlobs[recordedBlobs.length - 1]) && (recordedBlobs[recordedBlobs.length - 1] <= (silentBlobSize + maxMinRangeValue))) && (((silentBlobSize - maxMinRangeValue) <= blobSize) && (blobSize <= (silentBlobSize + maxMinRangeValue)))){
        silentCount += 1
      }
      else{
        silentCount = 0
      }
      
      recordedBlobs.push(blobSize)
  
      return silentCount === thresholdSilentCount
  
    };
    

    const playResponseAndUpdateConversation = async (textData, conversationData, voiceId, sst_elapsed_time, assistant_elapsed_time, totalStartTime, optimize_streaming_latency) => {
      if (isTextOnlyRef.current){

          setIsRecording(false);
          setLoading(false);
          setConversation(conversationData);
          const total_elapsed_time = performance.now() - totalStartTime;

          setTimeData({
            sst_elapsed_time: sst_elapsed_time,
            assistant_elapsed_time: assistant_elapsed_time,
            elevenlabs_elapsed_time: null,
            totalTime: parseFloat(total_elapsed_time / 1000).toFixed(2)
          })

          return false;

        }
        else{
          const startTime = performance.now();
          const url = `https://api.elevenlabs.io/v1/text-to-speech/${voiceId}?optimize_streaming_latency=${optimize_streaming_latency}`;
          const api_key = process.env.REACT_APP_ELAB_API_KEY
      
          const headers = {
            Accept: 'audio/mpeg',
            'xi-api-key': api_key,
            'Content-Type': 'application/json',
          };
          const data = {
            text: textData,
            model_id: 'eleven_monolingual_v1',
            voice_settings: { stability: 0.6, similarity_boost: 0.85 },
          };
      
          try {
            const response = await fetch(url, {
              method: 'POST',
              headers: headers,
              body: JSON.stringify(data),
            });
      
            if (response.ok) {
              const audioBlob = await response.blob();
              const audioUrl = URL.createObjectURL(audioBlob);
              
              const audio = document.getElementById('audioPlayer');
              audio.src = audioUrl;
              const playPromise = new Promise((resolve) => {
                  audio.onended = resolve;
              });
  
              setIsRecording(false);
              setLoading(false);
              setConversation(conversationData);

              const elevenlabs_elapsed_time = performance.now() - startTime;
              const total_elapsed_time = performance.now() - totalStartTime;

              setTimeData({
                sst_elapsed_time: sst_elapsed_time,
                assistant_elapsed_time: assistant_elapsed_time,
                elevenlabs_elapsed_time: parseFloat(elevenlabs_elapsed_time / 1000).toFixed(2),
                totalTime: parseFloat(total_elapsed_time / 1000).toFixed(2)
              })
  
              audio.play().catch(error => {
                setAlert({
                  title: "Error",
                  message: 'Audio playback failed',
                  open: true
                })
                  console.error('Audio playback failed:', error);
              });
              await playPromise;
            } else {
              
              setAlert({
                title: "Error",
                message: 'Text to speech conversion failed',
                open: true
              })
              console.error('Text to speech conversion failed');
            }
          } catch (error) {
            console.error('Error:', error);
          }
        }
      };
    

    const continueConversation = () => {
      if (isCoversationStartedRef.current && !isTextOnlyRef.current)
        setIsRecording(true);
      else{
        setIsConversationStarted(false)
        setIsRecording(false)
        setLoading(false)

      }
    };

    const handleAlertClose = () => {
      setAlert({
        open: false,
        title: "",
        message: ""
      });
     };
    
     const handleInstructionAlertClose = () => {
      setInstructionAlert({
        open: false,
        title: "",
        message: ""
      });
     };

    const startConversation = async () => {
      if (user?.token_balance <= 0){
        setAlert({
          open: true,
          title: "Error",
          message: "You dont have enough tokens to start a conversation, please subscribe to one of our plans."
        })
        return
      }
      
      if (showInstructions === 'true'){
  
        setInstructionAlert({
          title: "Information",
          message: instructionAlertMessage,
          open: true
        })

      }
      else{
        
        setIsConversationStarted(true)  
        setIsRecording(true)
      }
      };
      
      const handleOnContinueClick = (dontShowAgain=false) => {
        setShowInstructions(!dontShowAgain);
        localStorage.setItem('showInstructions', !dontShowAgain.toString());
      
        handleInstructionAlertClose()

        setIsConversationStarted(true)  
        setIsRecording(true)
  
        };

      const handlePause = () => {          
        setIsConversationStarted(false)
        setIsRecording(false)
        setLoading(false)

      };
        

      
      function stopConversation() {
        window.location.reload()
      }

      function handleSelectAssistant() {
        setState({
          choose: true
        })
      }

return (
    
    <Container>
        <Alert alert={alert} handleAlertClose={handleAlertClose}  />
        <InstructionAlert alert={instructionAlert} handleAlertClose={handleInstructionAlertClose} onContinueClick={handleOnContinueClick} />
        <Box >
            <Box sx={{ padding: 'auto', width: "100%", mt: 4, mb: 4, display:'flex', justifyContent: 'center', alignItems:'center', textAlign: 'center' ,height: "200px"}}>
        
              <img src={require("../../assets/img/recording162.gif")} style={{ display: isRecording? 'flex': 'none'}} alt="Recording" />
              <ReactMic
                record={isRecording}
                className={isRecording? "sound-wave": "sound-wave-hidden"}
                onStop={(e) => {
                  onStop(e, selectedAssistant, threadRef.current);
                }}
                onData={onData}
                onStart={onStart}
                strokeColor={theme.button.primary.text}
                backgroundColor={theme.button.primary.bg}
              />
              <img src={require("../../assets/img/loader.gif")} width={"100%"} style={{display: loading? 'flex': 'none'}}  alt="Loader" />
              <img src={require("../../assets/img/mic162.png")} style={{display: (!loading && !isRecording)? 'flex': 'none'}}  alt="Mic" />
            
            </Box>
            <Grid container spacing={2}>
                <Typography sx={{mb:2, fontSize: {xs: 12, md: 12, margin: 0, padding:0}}} variant="p"> Click the 'Start Conversation' button when you are ready to ask questions.</Typography>
           
                <Grid item xs={6}>
                  <PrimaryActionButton  onClick={() => isConversationStarted ? stopConversation(): startConversation()} condition={isConversationStarted? "true": "false"} >
                      {
                        isConversationStarted ?
                        <>
                          <Box sx={{ display: { xs: 'none', md: 'block',  }}}>
                            Stop
                          </Box>
                          <Box sx={{ display: { xs: 'block', md: 'none',  }}}>
                            Stop
                          </Box>
                        </>:
                        <>
                          <Box sx={{ display: { xs: 'none', md: 'block',  }}}>
                            Start 
                          </Box>
                          <Box sx={{ display: { xs: 'block', md: 'none',  }}}>
                            Start
                          </Box>
                        </>
                      }
                  </PrimaryActionButton>
                </Grid>
                <Grid item xs={3}>
                  <PrimaryActionButton  onClick={() => handlePause()} disabled={!isConversationStarted} >
                    <PauseIcon />
                  </PrimaryActionButton>
                </Grid>
                <Grid item xs={3}>
                  <PrimaryActionButton  onClick={() => handleSelectAssistant()} disabled={isConversationStarted} >
                    <ArrowBack />
                  </PrimaryActionButton>
                </Grid>
                <audio id="audioPlayer" hidden controls />
            </Grid>
        </Box>
    </Container>
)
}